



































import { Component, Vue } from 'vue-property-decorator';

import BattletrisIcon from '../icons/battletris.vue';
import CrossedSowrdsIcon from '../icons/crossed-swords.vue';
import FlaskIcon from '../icons/flask.vue';
import ScrollBookIcon from '../icons/scroll-book.vue';
import TargetDummyIcon from '../icons/target-dummy.vue';
import ViewWrapper from '../components/ViewWrapper.vue';

@Component({
  components: {
    BattletrisIcon,
    CrossedSowrdsIcon,
    FlaskIcon,
    ScrollBookIcon,
    TargetDummyIcon,
    ViewWrapper,
  },
})
export default class Tutorial extends Vue {}
